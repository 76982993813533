<template>
  <div>
    <a-alert
      message="Connectivity search allows you to find the most important paths connecting any two nodes in Systems immunology KG."
      class="scholar-tips"
      closable
    />
    <div class="iframe">
      <iframe
        id="content"
        frameborder="no"
        border="0"
        width="100%"
        height="100%"
        marginwidth="0"
        marginheight="0"
        scrolling="no"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { users } from "@/api/modules/login"

export default {
  name: "DirectionalAnalysis",
  data() {
    return {
      dataSource: [],
      name: {},
      msg: "hello world",
    }
  },
  mounted() {
    users()
      .then((res) => {
        this.$store.commit("analysis/setAuthenticated", true)
      })
      .catch((error) => {
        if (error.code === 401) {
          this.$store.commit("analysis/setAuthenticated", false)
          this.$router.push({ name: "Login" })
        }
      })
      .finally(() => {
        this.$store.commit("analysis/setLoading", false)
      })
    let iFrame = document.getElementById("content")
    // iFrame.src = `https://graph-${
    //   window.location.host.indexOf("dev") !== -1 ? "dev" : "dev"
    // }.alivex.cn/index.html?t=202`
    iFrame.src =
      window.location.host.indexOf(":") !== -1
        ? "http://localhost:7777/"
        : `https://graph-${
            window.location.host.indexOf("dev") !== -1 ? "dev" : "dev"
          }.alivex.cn/index.html?t=202`
    //iframe加载完毕后再发送消息，否则子页面接收不到message
    iFrame.onload = function() {
      //iframe加载完立即发送一条消息
      iFrame.contentWindow.postMessage(
        { token: localStorage.getItem("token") },
        "*"
      )
    }
    this.$store.commit("analysis/setLoading", false)
    window.addEventListener("message", (event) => {
      var origin = event.origin
      if (
        (window.location.host.indexOf(":") !== -1
          ? "http://localhost:7777"
          : `https://graph-${
              window.location.host.indexOf("dev") !== -1 ? "dev" : "dev"
            }.alivex.cn`) === origin
      ) {
        if (event.data.height) {
          iFrame.height = event.data.height
        }
        if (event.data.width) {
          iFrame.width = event.data.width
        }
      }
    })
  },
  methods: {
    handleOriginSearch(value) {},
    onOriginSelect(value) {},
    handleEndSearch(value) {},
    onEndSelect(value) {},
  },
}
</script>

<style lang="scss" scoped>
/deep/ .ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #2db1b0;
}
.iframe {
  height: calc(100vh - 201px);
}
.main {
  @include flex-contianer(row);
  .search {
    @include flex-contianer(column);
    width: 300px;
    margin-right: 24px;
    .input {
      border-color: #2db1b0;
      &:hover {
        border-color: #2db1b0;
      }
    }
    .search-btn {
      margin-top: 1.5em;
    }
  }
  .table {
    flex: 1;
  }
}
</style>
